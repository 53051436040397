.bottom-callout {
  width:100%;
  background-color: #F0F0F0;
  .items {
    padding:100px 0 150px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .txt-panel, .image-panel {
      width:50%;
    }
    .txt-panel {
      margin-left: 100px;
      margin-top: 15%;
      @media only screen and (max-width: $breakpoint-small) {
        margin-top: 17%;
      }
    }
    .image-panel {
      .square {
        width:100%;
        height: 0;
        padding-top: 70%;
        max-width: 675px;
      }
      .scrim-square {
        position: absolute;
        bottom:-50px;
        right:-50px;
        width:75%;
        height: 0;
        padding-top: 46%;

      }
      .square {
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }

}