.page-message-center {
  .holder {
    @include master-margin-tb();
    @include master-padding-tb();
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
    }
    .l {
      width:200px;
      max-width: 200px;
      min-width: 200px;
      padding-right:80px;
      margin-right: 80px;
      border-right:1px solid #707070;
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
        max-width: unset;
        min-width: unset;
        border-right: none;
        padding-right:0;
        margin-right: 0;
      }

      .items {
        display: block;
        @media only screen and (max-width: $breakpoint-small) {
          display: inline-flex;
          .row-outer {
            margin-right: 40px;
          }
        }
        .row-outer {
          .row {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            margin:20px 0;
            img {
              width:18px;
              margin-right: 30px;
            }

            .txt {
              text-align: left;
              @include calc(width,'100% - 48px');
              @include transition-all();
            }
            .txt, .count {
              @include font-ntf-grand();
              font-size:32px;
              color:#000;
            }
            .count {
              margin-left: 10px;
            }
          }//row
          a {
            text-decoration: none;
          }

          @media only screen and (max-width: $breakpoint-small) {
            img {
              display: none;
            }
            &:first-child {
              img {
                display: block;
              }
            }
          }
        }

      }

    }
    .r {
      width:100%;
      .inner {
        padding:20px;
        @media only screen and (max-width: $breakpoint-small) {
          margin-top: 30px;
          padding: 0;
        }

        #message-box {
          .row {
            margin-bottom: 20px;
            display: flex;
            .col1 {
              $tw:150px;
              width:$tw;
              max-width:$tw;
              min-width: $tw;
              @media only screen and (max-width: $breakpoint-small) {
                $tw:100px;
                width:$tw;
                max-width:$tw;
                min-width: $tw;
              }
            }
            .col1, .col2 {
              padding:5px;
              a {
                color:#000;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}

body.page-messages {

}