body.home {
  .txt-image {
    margin-top: 50px;
    //margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $breakpoint-small) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
    .copy {
      margin-top: 10px;
    }
    .txt {
      width:50%;
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
        padding-top: 40px;
      }
    }
    .image-panel {
      width:50%;
      @include master-position-left(); //offset the padding and squish to the side
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
    }


    .image-panel {
      .scrim-square, .square {
        width:100%;
        height: 0;
        padding-top: 81.5%;
      }
      .scrim-square {
          position: absolute;
          top:-50px;
          left:-50px;
          @media only screen and (max-width: $breakpoint-small) {
            top:-30px;
            left:-30px;
          }

        }
        .square {
          background-size: cover;
          background-repeat: no-repeat;
        }
    }
  }

  #register-errors {
    margin: 5px 0;
  }

}
