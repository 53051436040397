.rte{
  &:not(.no-max-width) {
    max-width:$max-width-copy;
    //margin-left: auto;
    //margin-right: auto;
  }
  color:#000;
  width: 100%;
  @include font-roboto-regular();
  font-size: 21px;
  line-height: 1.1em;
  @media only screen and (max-width: $breakpoint-small) {
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.0em;
    @include font-ntf-grand();
    word-break: break-word;
    margin: 0 0 10px 0;
    text-transform: uppercase;
    //color:$color-dark;
    font-weight: normal;
  }
  h1 {
    font-size:70px;
    @media only screen and (max-width: $breakpoint-small) {
      font-size:46px;
    }
  }
  h2 {
    font-size:50px;
    @media only screen and (max-width: $breakpoint-small) {
      font-size:36px;
    }
  }
  h3 {
    font-size:40px;
    @media only screen and (max-width: $breakpoint-small) {
      font-size:30px;
    }
  }
  h4 {
    @include font-roboto-bold();
    font-size:30px;
    @media only screen and (max-width: $breakpoint-small) {
      font-size:24px;
    }
  }
  h5, .rte-h5 {
    font-size:24px;
    @media only screen and (max-width: $breakpoint-small) {
      font-size:20px;
    }
  }


  h6, p, a, li, a, tr, th, td, label, input, option  {
    font-size: 18px;
    @include font-roboto-regular();
    @media only screen and (max-width: $breakpoint-small) {
      font-size:16px;
    }

    b, strong {
      @include font-roboto-bold();
    }
    i, em {
      font-style: italic;
    }
  }
  input {

  }
  a {
    //top:10px;
    //color:inherit!important;
    //@extend .link-underline-leftright-dark;
    @include transition-all();
    text-decoration:none;
    color:$color-teal;
    &:hover {
      text-decoration:underline;
    }
  }
  p, h6 {
    word-break: break-word;
    margin-bottom: 15px;
    @media only screen and (max-width: $breakpoint-small) {
      //margin-bottom: px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  b, strong, em, i, del, strike, u {
    color: inherit;
  }
  ul, ol {
    margin-bottom: 20px;
  }
  small {
    font-size:14px;
  }
  figure {
    margin-bottom: 20px;
    img, svg {
      max-width: 100%;
    }
  }

  ul, ol {
    li {
      margin-bottom: 10px;
      margin-left: 17px;
    }
  }

  .important, .error {
    //font-weight: bold;
    color:#ff0000;;
  }

  &.tight-margins {
    p, li, h3, h4, h5, h6 {
      margin-bottom: 5px;
    }
  }

  .select {
    position: relative;
    height: 47.5px;
    background: white;
    //box-shadow: 0 3px 0 rgba(0,0,0,.05);


    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      right: 10px;
      bottom: 0;
      margin: auto;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #000 transparent transparent transparent;
      pointer-events: none;
    }

    &:before {
      width: 30px;
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      background: #fff;
      content: '';
      pointer-events: none;
    }

    &:hover {
      &:before {
        //background: #ffcfcf;
      }
    }

    select {
      font-size: 18px;
      border: none;
      box-shadow: none;
      border-radius: 0;
      background: transparent;
      height: 100%;
      width: 100%;
      cursor: pointer;
      outline: none;
      padding-right: 35px;
      padding-left: 15px;
      @media only screen and (max-width: $breakpoint-smaller) {
        padding-left: 0;
      }
      //border: 1px solid #a52c2c;

      // Disable default styling on ff
      -moz-appearance: none;

      // Disable ugly ass outline on firefox
      &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
      }

      // Disable default styling on webkit browsers
      -webkit-appearance: none;

      // Disable default arrow on IE 11+
      &::-ms-expand {
        display: none;
      }

      &:focus {
        border-color: $color-gray;
      }
    }
  }
}
