body.general {
 .site-content2 {
   margin-top:40px;
   .copy {
      margin-top:40px;
   }
   .breadcrumbs {
     margin: 20px 0 20px;
     a {
       color:#000;
       text-decoration: none;
       display: inline-flex;
       align-items: center;
       .icon {
         width:15px;
         margin-right: 5px;
         top:3px;
         img {
           width:100%;
         }
       }
     }
   }
 }
}