.user-account-form {
  margin: 20px 0;

  label {
    display: block;
  }
  input, .need-to-register {
    @include calc(width,'100% - 120px');
    @media only screen and (max-width: $breakpoint-small) {
      @include calc(width,'100% - 20px');
    }
  }
  .need-to-register {
    margin-bottom: 15px;
  }
  input {
    padding:10px 10px;
    border: 1px solid #D6D6D6;
    @include set-input-placeholder-color(#171415);
    color:#171415;
    margin: 10px 0 30px;

  }
  .btn-outer {
    margin: 0;
  }
  .mmddyyyy {
    display: flex;
    justify-content: space-between;
    @include calc(width,'100% - 100px');
    margin: 10px 0 20px;
    @media only screen and (max-width: $breakpoint-small) {
      @include calc(width,'100%');
    }
    @media only screen and (max-width: $breakpoint-smaller) {
      display: block;
    }


    .select {
      padding:0 10px;
      border: 1px solid #D6D6D6;
      @include calc(width,'33% - 30px');
      cursor: pointer;
      @media only screen and (max-width: $breakpoint-smaller) {
        @include calc(width,'100% - 20px');
        margin-bottom: 20px;
      }
    }
  }

  p.first {
    margin-top: 40px;
  }
  p {
    margin-bottom: 10px;
    a {

    }

  }
}
#error-zone {
  margin: 10px 0;
  * {
    color:#ff0000;
  }
  color:#ff0000;
}