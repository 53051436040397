.page-dashboard {
  .featured-dashboard-image {
    width:100%;
    max-height: 728px;
    height: 80vh;
    @media only screen and (max-width: $breakpoint-small) {
      height: 60vh;
      min-height: 500px;
    }
    .bgimg {
      .capper, .capper-padded {
        height: 100%;
      }
    }
    .dashboard {
      position: absolute;
      bottom:0;
      @include master-position-left();
      @include width-100-minus-master-position-left();
      background-color: #fff;
      .dashboard2 {
        padding:20px 40px;
        .row1 {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .l {

          }
          .r {
            text-align: right;
            .current-menu-user-id {
              margin-top: 5px;
              @include font-ntf-grand();
              font-size: 18px;
            }
          }
        }
        .boxes {
          display: flex;
          justify-content: space-between;
          margin: 40px 0 20px;
          @media only screen and (max-width: $breakpoint-small) {
            display: block;
          }

          .box {
            @include calc(width,'33% - 20px');
            text-align: center;
            border:1px solid #B5B5B5;

            @media only screen and (max-width: $breakpoint-small) {
              width: 100%;
              display: flex;
              margin-bottom: 20px;
              &:last-child {
                margin-bottom: 0;
              }
            }

            .box-header {

              @extend .scrim-square;
              color:#fff;
              padding:20px;
              @include font-ntf-grand();
              font-size: 32px;
              text-transform: uppercase;

              @media only screen and (max-width: $breakpoint-small) {
                width:150px;
                max-width: 150px;
                padding:20px;
                font-size: 20px;
              }
            }
            .box-value {
              color:#1A1416;
              padding:40px 0;
              @include font-ntf-grand();
              font-size: 62px;
              text-transform: uppercase;

              @media only screen and (max-width: $breakpoint-small) {
                padding:20px;
                font-size: 30px;
              }
            }
          }
        }//boxes
        .legal {
          * {
            @include font-roboto-regular();
            font-size: 12px;
            color:#7B7B7B;
          }

          margin:10px 0;
        }
      }

    }
  }//featured-dashboard-image

  .latest-promotions {
    background-color: #F0F0F0;
    .latest-promotions-top {
      text-align: center;
      padding:80px 0;
      margin:0 auto;
      max-width: 800px;
    }
    .promotions-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 80px;
      row-gap: 80px;
      padding:0 0 80px 0;
      @media only screen and (max-width: $breakpoint-small) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        row-gap: 20px;
        padding:0 0 20px 0;
      }
      @media only screen and (max-width: $breakpoint-tiny) {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 0;
        row-gap: 0;
      }
      .promotion {
        .graphic {
          .img {
            width:100%;
            aspect-ratio: 1.95;
          }
          .titles {
            background-image: linear-gradient(to right, #EF1993 0%, #52247F 100%);
            .titles2 {
              padding:20px;
              h5 {
                @include font-ntf-grand();
                font-size: 34px;
                letter-spacing: -0.02em;
                color:#fff;
                text-transform: uppercase;
              }
              p {
                @include font-roboto-regular();
                font-size: 16px;
                color:#fff;
              }
            }
          }//titles
        }//graphic
        .copy {
          .desc {
            margin:20px 0;
          }

        }
      }
    }
  }//latest-promotions

  .calculator {
    background-color: #000;
    padding:80px 0;
    .rte {
      * {
        color: #fff;
      }
    }
    .flx {
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: $breakpoint-small) {
        display: block;
      }
      .l, .r {
        @include calc(width,'50% - 20px');
        @media only screen and (max-width: $breakpoint-small) {
          width: 100%;
        }
      }
      .r {
        top: 10px;
        @media only screen and (max-width: $breakpoint-small) {
          top:0;
          margin-top: 40px;
        }
        .toptxt {
          width:70%;
        }
        #points-form-results-msg {
          display: none;
          a {
            text-decoration: underline;
          }
        }
        .points-form {
          display: flex;
          justify-content: space-between;
          .input {
            width:100%;
            input {
              @include calc(width,'100% - 40px');
              border:1px solid #fff;
              background-color: #000;
              padding:12px 20px;
              z-index: 2;
              @include set-input-placeholder-color(#fff);
              @media only screen and (max-width: $breakpoint-small) {
                padding:14px 20px 12px;
              }
            }
          }
        }
        .l2 {
          margin-top: 10px;
        }
      }
    }
  }//calculator

  .footer-panels {
    padding: 80px 0;

    .panels {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 80px;
      row-gap: 80px;
      @media only screen and (max-width: $breakpoint-small) {
        grid-template-columns: repeat(1, 1fr);
      }
      .panel {
        background-color: #000;
        .img {
          width: 100%;
          aspect-ratio: 1.95;
        }
        .inner {
          padding:30px;
          //@include calc(min-height,'100% - 16vw');
          .rte {
            height: 100%;
            h1, h2, h3, h4, h5, h6 {
              text-transform: uppercase;
            }
            * {
              color:#fff;
            }
          }
          .txt {
            @include calc(height,'100% - 100px');
          }
          .btn-outer {
            margin: 20px 0 0;
          }

        }
      }
    }
  }

}