.featured-image {
  width:100%;
  height: 510px;
  @media only screen and (max-width: $breakpoint-small) {
    height: 150px;
  }
  .bgimg {
    .capper, .capper-padded {
      height: 100%;
    }
  }
  .blocks {
    position: absolute;
    bottom:0;
    @include master-position-left();
    width: 100%;
    .title-block, .caption-block {
      visibility: hidden;
      h1, p {
        background-color: #fff;
        display: inline-block;
        max-width: 500px;
        margin:0;
      }
      h1 {
        padding:20px;
      }
      p {
        padding:20px 40px;
      }

    }
    .caption-block {
      margin-left: 130px;
    }
  }

}

body.page-message-center .featured-image,
body.page-messages .featured-image,
body.featured-image-mini .featured-image {
  height: 245px;
  @media only screen and (max-width: $breakpoint-small) {
    height: 150px;
  }
}
body.page-faq  .featured-image {
  @media only screen and (max-width: $breakpoint-small) {
    height: 325px;
  }
}