.page-account-settings  {
  .topbar {
    @include master-margin-tb();

  }
  .holder {
    @include master-padding-tb();
    @include master-margin-tb();
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
    }
    .l, .r {
      @include calc(width,'50% - 40px');
      @media only screen and (max-width: $breakpoint-small) {
        @include calc(width,'100%');
      }
    }
    .row-buttons {
      display: flex;
      justify-content: space-between;
    }
    .field-box {
      background-color: #F0F0F0;
      margin-bottom: 60px;
      .field-box2 {
        padding:20px;
      }
      .top {
        display: flex;
        justify-content: space-between;
        * {
          margin:0;
        }

        .edit {
          text-align: right;
        }
      }//top
      .fields {
        .row {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin:30px 0;

          //READ ONLY TOGGLE
          .edit-display { display: block;}
          .readonly-display { display: none;}
          &.readonly {
            .edit-display { display: none;}
            .readonly-display { display: block;}
          }


          .row-l {
            $tw:185px;
            max-width: $tw;
            min-width: $tw;
            width: $tw;
            margin-right: 20px;
            label {
              @include font-ntf-grand();
              font-size: 32px;
              top:8px;
            }
          }

          .row-r {
            width:100%;
          }
          input {
            background-color: transparent;
            padding:10px 20px;
            text-align: left;
            @include calc(width,'100% - 40px');
            &.type- {
              background-color: #fff;
            }
          }
          select {
            padding:10px 20px;
            background-color: transparent;
            &.type- {
              background-color: #fff;
            }
          }

          .error-msg {
            display: none;
            margin: 5px 0;
          }
          &.error {
            .error-msg {
              display: block;
            }
          }

          &.readonly {
            .row-l {
              label {
                top:0;
              }
            }
          }
        }//row
      } //fields
    }
  }
}
