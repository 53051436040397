.expandables {
  margin: 40px 0;
}
.expandables-hook {
  margin-top: 10px;
  border-bottom: 1px solid #000;
  padding-bottom: 20px;
  margin-bottom: 20px;
  .rte {
    max-width: none!important;
  }
  .title-description {
    flex: 0 1 auto;
    width:100%;
    margin-top: 20px;
    .title {
      display: inline-flex;
      background-color: transparent;
      cursor: pointer;
      align-items: center;
      border:1px dashed rgba(0, 0, 0, 0);
      text-align: left;
      &:focus {
        //outline: 0;
        //border:1px dashed #000;
      }
      img {
        width:8px;
        display: inline;
        margin-left: 10px;
        @include transition_all();
      }


      h2 {
        font-weight: normal;
      }
      h2, h2 * {
        display: inline;
        position: relative;
        @include font-ntf-grand();
        text-transform: uppercase;
        font-size: 32px;

      }

    } //title

    .description {
      @include transition_all();
      visibility: hidden;
      height: 0;
      opacity: 0;

    } //description
  }

  .item.open {
    .title {
      img {
        transform: rotate(90deg);
      }
    }
    .description {
      visibility: visible;
      height: auto;
      opacity: 1;
      margin-top: 10px;
    }
    .toggle-icon {
      transform: rotate(90deg);
    }
  }
}  //hook




