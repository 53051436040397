.page-annual-statements {
  .holder{
    display: flex;
    justify-content: space-between;
    @include master-padding-tb();
    @include master-margin-tb();
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
    }
    .l {
      width: 100%;
      border-right:1px solid #D6D6D6;
      padding-right:80px;
      margin-right: 80px;
      @media only screen and (max-width: $breakpoint-small) {
        border-right:0;
        padding-right:0;
        margin-right: 0;
        border-bottom: 1px solid #D6D6D6;
        padding-bottom: 80px;
        margin-bottom: 80px;
      }
      .physical {
        margin: 40px 0 0;
      }
      .terms-checkbox {
        margin: 20px 0;
        input {
          cursor: pointer;
        }
      }
      #field-statement {
        border-radius: 0;
        border:1px solid #D6D6D6;
        padding:5px 10px;
        cursor: pointer;
        font-size: 18px;
        @include font-roboto-regular();
        color:#171415;
        margin:10px 0 20px;
      }
    }
    .r {
      max-width: 400px;
      .marker {
        .img {
          width: 100%;
          aspect-ratio: 2.27;
          margin-bottom: 40px;
        }
      }
    }
    .btn-outer {
      margin:15px 0;
    }
  }
}