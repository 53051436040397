.ti__footer {
  display: flex;
  flex-direction: column;
  max-width: 1650px;
  width: 85%;
  margin: 0 auto;
  padding-bottom: 30px;
  padding-top: 30px;
  overflow: hidden;


  img {
    max-width: 100%;
  }



  @include breakpoint(1250px) {
    padding-bottom: 90px;
  }

  &__logo {
    max-width: 180px!important;
    margin-bottom: 20px;
  }

  &__copyright {
    font-size: 16px;
    color: $color-gray;
    line-height: 150%;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    max-width: 280px;
    margin-bottom: 20px;

    &__logos {
      display: flex;
      gap: 15px;
      align-items: center;

      a {
        max-width: 100px;

        &:nth-child(2) {
          max-width: 75px;
        }
      }
    }
  }

  &__columns {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;

    @include breakpoint(800px) {
      grid-template-columns: 1fr 1fr;
      gap: 60px 30px;
    }

    @include breakpoint(1500px) {
      display: flex;
      justify-content: space-between;
      gap: 60px;
    }

    &__inner {
      order: 3;
      display: grid;
      gap: 45px;

      @include breakpoint(400px) {
        grid-template-columns: 1fr 1fr;
      }

      @include breakpoint(800px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column: 1 / 4;
      }

      @include breakpoint(1500px) {
        order: 2;
        grid-column: auto;
        flex: 2;
      }
    }
  }

  &__column {
    &--copyright {
      order: 1;
      margin-top: -12px;
      @include font-roboto-regular();

      @include breakpoint(1500px) {
        flex: 0.75;
      }
    }

    &--form {
      order: 2;

      @include breakpoint(800px) {
        grid-column: 2 / 4;
      }

      @include breakpoint(1500px) {
        order: 3;
        grid-column: auto;
        flex: 1;
      }

      h2 {
        margin-top: 0;
        margin-bottom: 20px;
        @include font-roboto-regular();
        font-size: 17px;
        color: $color-gray;
      }

      form {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .ti__email {
          flex: 2;
          padding: 16px;
          font-size: 18px;
          border: 1px solid $color-gray;
          width: 160px;

          @include breakpoint($xs) {
            width: auto;
          }
        }

        .ti__submit {
          flex: 1;
          background-color: $color-dark-gray;
          color: white;
          font-size: 16px;
          padding: 16px;
          text-transform: uppercase;
          border: none;
          appearance: none;
        }
      }
    }

    &--menu {
      display: flex;
      flex-direction: column;
      gap: 30px 15px;

      @include breakpoint(1250px) {
        gap: 20px;
      }

      a {
        @include font-roboto-regular();
        font-size: 14px;
        text-decoration: none;
        color: $color-gray;
        text-transform: uppercase;
        transition: text-decoration 0.3s;
        text-decoration: underline solid transparent;

        &:hover {
          text-decoration: underline solid black;
        }
      }
    }

    &--contact {

    }
  }

  &__social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    gap: 10%;

    a {
      flex: 1;
      max-width: 20px;

      img {
        opacity: 0.6;
      }
    }
  }

  &__apps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    left:-8px;
  }

  &__contact_links {
    display: flex;
    flex-direction: column;
    gap: 30px;

    a {
      @include font-roboto-regular();
      font-size: 15px;
      text-decoration: none;
      color: $color-gray;
      text-transform: uppercase;
      transition: text-decoration 0.3s;
      text-decoration: underline solid transparent;

      &:hover {
        text-decoration: underline solid black;
      }

      position: relative;
      padding-left: 30px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 18px;
        width: 18px;

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      &.ti__footer__telephone {
        &:after {
          background-image: url('../images/global/icon-phone.svg');
        }
      }

      &.ti__footer__email {
        &:after {
          background-image: url('../images/global/icon-email.svg');
        }
      }

      &.ti__footer__directions {
        &:after {
          background-image: url('../images/global/icon-location.svg');
        }
      }
    }
  }
}