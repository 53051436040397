.page-program-overview {
  .top-benefits {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @extend .defailt-top-bottom-margin;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
    }
    .l, .r {
      @include calc(width,'50% - 40px');
      @media only screen and (max-width: $breakpoint-small) {
        @include calc(width,'100%');
      }
    }
    .l {
        .txt {
          margin-top: 40px;
        }
    }
    .r {
      * {
        @include font-roboto-regular();
        font-size: 18px;
      }
      .rows {
        margin-top: 20px;
        .row {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 10px;
          .col1 {
            width:36px;
            min-width: 36px;
            max-width: 36px;
            img {
              width:100%;
            }
          }
          .col2 {
            width:100%;
            margin-left: 20px;
          }
        }
      }
    }

  }

  .points-boxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 80px;
    row-gap: 80px;
    margin-bottom: 100px;
    @media only screen and (max-width: $breakpoint-small) {
      grid-template-columns: repeat(1, 1fr);
    }
    .box {
      //@include calc(width,'33% - 80px');
      .icon {
        display: flex;
        min-height: 130px;
        align-items: center;
        justify-content: center;
        img {
          width:70%;
          height: auto;
        }
      }
      .copy {
        @include calc(height, '100% - 130px');
        .blackbox {
          background-color: #000;
          @include calc(height, '100% + 20px');
          .blackbox2 {
            padding: 20px;
            @include calc(height, '100% - 30px');
            * {
              color:#fff;
            }
          }
          .txt {
            @include calc(height, '100% - 40px');
          }
          .links {
            height: 20px;
            padding: 10px 0;
            display: flex;
            justify-content: space-between;
            * {
              @include font-ntf-grand();
              text-transform: uppercase;
              font-size: 24px;
              color:$color-teal;
              @include transition-all();
            }
            .crt {
              margin-left: 5px;
              top:1px;
              @include transition-all();
              display: inline-block;
              svg {
                width:10px;
                path {
                  @include transition-all();
                  fill:$color-teal;
                }
              }
            }
            a, .overlay-hook {
              cursor: pointer;
              &:hover {
                * {
                  color:#fff;
                }
                .crt {
                  transform: translateX(3px);
                  svg {
                    path {
                      fill:#fff;
                    }
                  }
                }
              }
            }
          }
        }
        .scrim-square {
          position: absolute;
          width:100%;
          height: 100%;
          bottom: -60px;
          lefT:-40px;
        }
      }

    }
  }//points-boxes


  .benefits-table-zone {
    text-align: center;
    background-color: $color-lighter-gray;
    @include master-padding-tb();
    .rte {
      margin:0 auto;
    }
  }
  .benefits-table {
    margin:40px 0;
    td, th, {
      padding:10px 20px;
      @include font-roboto-regular();
      font-size: 18px;
      text-align: center;
      @media only screen and (max-width: $breakpoint-small) {
        font-size: 16px;
        padding:5px 10px;
      }
      @media only screen and (max-width: $breakpoint-smaller) {
        font-size: 10px;
        padding:2px 4px;
      }
    }
    .col1 {
      background-color: #000;
      color:#fff;
      border-bottom: 1px solid #7C7C7C;
      border-right: 1px solid #7C7C7C;
    }
    .col2 {
      color:#fff;
    }
    .col2, .col3, .col4, .col5 {
      border-bottom: 1px solid #E4E4E4;
      border-right: 1px solid #E4E4E4;
    }
    .cg2 {
      background-image: linear-gradient(#F15268 0%, #F6AE5E 100%);
    }
    tr.rh .col1 {
      background-color: $color-lighter-gray;
    }
    .col1 {
      text-align: left!important;
      min-width: 300px;
      @media only screen and (max-width: $breakpoint-small) {
        min-width: 100px;
      }
      @media only screen and (max-width: $breakpoint-smaller) {
        min-width: 50px;
      }
    }
    tr.rh .col3, tr.rh .col4, tr.rh .col5 {
      background-color: #000;
      color:#fff;

    }
    tr.rh .col2, tr.rh .col3, tr.rh .col4, tr.rh .col5 {
      border-top: 1px solid #7C7C7C;
    }
    tr.rr .col1 {
      border-left: 1px solid #7C7C7C;
    }
    tr.rr .col3, tr.rr .col4, tr.rr .col5 {
      background-color: #fff;
    }
    tbody tr:first-child .col1 {
      border-top: 1px solid #7C7C7C;
    }
    tr.rh *  {
      @include font-ntf-grand();
      font-size: 24px;
      text-transform: uppercase;
      @media only screen and (max-width: $breakpoint-small) {
        font-size: 20px;
      }
      @media only screen and (max-width: $breakpoint-smaller) {
        font-size: 12px;
      }
    }

  }//benefits-table

  .faqs-bar {
    margin:60px 0;
    text-align: right;
    .item {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      .l {
        @include font-ntf-grand();
        font-size: 34px;
        text-transform: uppercase;
      }
      .r {
        margin-left:20px;
      }
    }
  }//faqs-bar
}