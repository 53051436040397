#overlay,
.overlay-section {
  display: none;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;

  #overlay-scrim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    body.page-program-overview & {
      background-color: rgba(255, 255, 255, 1);
    }
  }

  #overlay2,
  #overlay-inner,
  .overlay-section,
  .overlay-video-wrapper,
  .overlay-video-wrapper iframe,
  .overlay-video-wrapper video {
    width: 100%;
    height: 100%;
  }
  .overlay-section {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }

  #overlay-inner2 {
    @include calc(width, '100% - 200px');
    @include calc(height, '100% - 100px');
    margin: 50px 100px;
    @media only screen and (max-width: $breakpoint-small) {
      @include calc(width, '100% - 40px');
      @include calc(height, '100% - 40px');
      margin: 20px;
    }
  }


  .overlay-video-caption {
    position: absolute;
    bottom: -45px;
    left: 0;
    width: 100%;
    @include font-roboto-bold();
    font-size: 22px;
    color: $color-blue;
  }
}

.overlay-x {
  position: absolute;
  z-index: 20;
  right: 0;
  top: 0;
}


// special screenings overlay
body.screenings-scroller {
  #overlay {
    position: absolute;
    #overlay-inner {
      height: auto;
    }
    #overlay-rsvp {
      display: block;
    }
  }

}

.overlay-meta {
  display: none;
}

#overlay-asset {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
    max-width: 100%;
  }
}

#overlay-custom {

  .rte-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: scroll;
    padding-top:50px;
    padding-bottom: 50px;
    @include calc(height,'100% - 100px');
  }
  .rte {
    margin:0 auto;
    overflow: scroll;
    max-width: $copyMaxWidth;

  }
}

#overlay-promotions-inner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  #overlay-promotions-inner2 {
    @include calc(width,'100% - 40px');
    max-width: 800px;
    overflow: auto;
    height: 100%;
    #overlay-promotions-inner3 {
      padding:20px;
      background-color: #fff;
      .fui-heading-h6 {
        font-style: italic;
      }
      .fui-input-container {
        .rte {
          width:100%;
          height: 100px;
          overflow: auto;
          border:1px solid #000;
          padding:10px;
          p {
            font-size: 60%;
          }
        }
      }
      .fui-page-row {
        @media only screen and (max-width: 700px) {
          display: block;
          .fui-field {
            display: block;
          }
        }

      }
    }
  }

}