#new-site-popup {
  position: fixed;
  top:0;
  left:0;
  display: none;
  background-color: rgba(0,0,0,0.5);
  z-index: 3459849584;
  @include vh100();
  width: 100%;
  .box-holder {
    display: flex;
    @include vh100();
    width: 100%;
    justify-content: center;
    align-items: center;
    .box {
      max-width: 785px;
      @include calc(width,'100% - 20px');
      background-color: #fff;
      .inner {
        padding:40px;
        .logo {
          img {
            height: 75px;
            width: auto;
          }
        }

        .close-button {
          position: absolute;
          top:15px;
          right:15px;
          transform: scale(0.75);
        }
        .rte {
          margin-top: 40px;
        }
        .btn-outer {
          margin-top: 20px;
        }
      }
    }
  }
}