header.header {
  top:0;
  left:0;
  width:100%;
  position: fixed;
  @include transition-all();
  z-index: 10000;
  height: $headerTall;
  background-color: #ffffff;
  .capper, .capper-padded, .mainbar{
    height: 100%;
  }

  .mainbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .l {
      margin-top: 30px;
      width: 263px;
      max-width: 263px;
      @media only screen and (max-width: $breakpoint-mobile-nav) {
        width: 263px;
        max-width: 263px;
        margin-top: 0;
      }
      @media only screen and (max-width: $breakpoint-tiny) {
        width: 130px;
        max-width: 130px;
      }
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        height: 55px;
        width: auto;
        top:-12px;
        @media only screen and (max-width: $breakpoint-mobile-nav) {
          top:0;
          width:100%;
          height: auto;
        }
      }
    }
    .r {
      @include calc(width,'100% - 100px');
      .current-menu-user {
        top:-20px;
        display: flex;
        justify-content: flex-end;
        @media only screen and (max-width: $breakpoint-mobile-nav) {
          top:0;
          right:0;
          @include calc(width,'100% - 60px');
          display: none!important;
        }
        a {
          color: $color-dark;
        }
      }
      .header-menu {
        display: inline;
        @media only screen and (max-width: $breakpoint-mobile-nav) {
          display: none;
        }
        li {
          display: inline;
          list-style: none;
          margin:0 0 0 30px;
          a {
            @include font-ntf-grand();
            font-size: 24px;
            color: $color-dark;
            text-transform: uppercase;
          }
        }
      }
    }
  }

}

@media only screen and (max-width: $breakpoint-mobile-nav) {
  header.header {
    height: $headerSmall;
    .mainbar {
      .l {

      }
    }
  }
}
