/////////////////////////////////////////////////////////
//GLOBAL TAGS
/////////////////////////////////////////////////////////
html, body {
  background-color: #fff;
  scroll-behavior: smooth;
}

body {
  @include vh100();
}

#container {
  width: 100%;
}

#content {
  @include vh100();
  .site-content2 {
    padding-top: $headerTall;
    margin-bottom: 80px;
    @media only screen and (max-width: $breakpoint-mobile-nav) {
      padding-top: $headerSmall;
    }

  }
}


#meta-sitewidth {
  display: none;
  z-index: 0;
  padding-top: $max-width-site;
}
#meta-breakpoint {
  display: none;
  z-index: 0;
  padding-top: $breakpoint-small;
}

a {
  text-decoration: none;
}

.generic-holder {
  @include master-padding-tb();
  min-height: 300px;

  .generic-holder-content {
    padding-top: 40px;
  }
}

.blocks-holder {
  width: 100%;
}
.block {
  width:100%;
  @include blocks-margin-tb();
}

.defailt-top-bottom-margin {
  margin:80px 0;
}