.link-underline-grow {
  display: inline-block;
  text-decoration: none;
  &:after {
    content:"";
    display: block;
    width:100%;
    height:2px;
    background-color: #000;
    @include transition_all();
    transform-origin: top center;
  }
  &:hover {
    &:after {
      transform: scaleY(2);
    }
  }
}
.link-underline-leftright {
  cursor:pointer;
  $transTime:250ms;
  $transEaseInOut:all $transTime ease-in-out;
  $transEaseIn:all $transTime ease-in;
  $transEaseOut:all $transTime ease-out;
  $transOff:all 0ms ease-in-out;
  color:#fff;
  transition: $transEaseInOut;
  display: inline-block;
  text-decoration: none;
  overflow: hidden;
  padding-bottom: 5px;
  &:before,
  &:after {
    content:"";
    position: absolute;
    @include calc(top,'100% - 4px');
    @include calc(left,'-100%');
    display: block;
    width:100%;
    height:1px;
    background-color: #fff;
    transform-origin: top center;
  }
  &:before {
    transition: $transOff;
  }
  &:after {
    @include calc(left,'100%');
    transition: $transEaseOut;
  }
  &.active {
    //color:$color-cyan;
    &:before {
      @include calc(left,'-100% + 20px');
    }
    &:after {
      @include calc(left,'-100% + 20px');
    }
  }
  &:hover {
    //color:$color-cyan;
    &:before {
      left:0;
      transition: $transEaseIn;
    }
    &:after {
      transition: $transEaseOut;
      transition-delay: $transTime;
      left:0;
    }
  }
  &.small {
    &:before,
    &:after {
      height:1px;
    }
  }
}
.link-underline-leftright-dark {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color: #000;
  }
}
.link-underline-leftright-red {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color: $color-red;
  }
}
.link-underline-leftright-white {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color: #fff;
  }
}

.miniButton {
  color:$color-dark;
  @include font-roboto-bold();
  font-size:12px;
  text-transform: uppercase;
  background-color: #ccc;
  border:1px solid #000;
  display: inline-block;
  margin-top:5px;
  padding:5px 5px 2px;
  @include transition-all();
}
button.only-mini {
  background-color: transparent;
  border: none;
  display: inline-block;
  .miniButton {
    cursor: pointer;
    margin-top:0;
    &:hover {
      background-color: #fff;
    }
  }
}



//elipse
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-underline-grow {
  color:$color-red;
  @include font-roboto-bold();
  font-size:18px;
  line-height: 22px;
  text-transform: uppercase;
  @media only screen and (max-width: $breakpoint-mobile-nav) {
    font-size:15px;
    line-height: 15px;
  }
}

.generic-image-hover {
  cursor: pointer;
  opacity:1;
  @include transition-opacity();
  &:hover {
    opacity:0.85;
  }
}
.scrim {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: rgb(26,98,137);
  background: linear-gradient(180deg, rgba(26,98,137,1) 0%, rgba(7,64,96,1) 68%, rgba(0,55,85,1) 100%);
}


.global-close {
  width:40px;
  height: 40px;
  display: block;
  cursor: pointer;
  &:before, &:after {
    content:"";
    width:40px;
    height: 5px;
    border-radius: 7px;
    background-color: $color-dark;
    position: absolute;
    top:16px;
    left:0;
    @include transition-all();
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:hover {
    &:before, &:after {
      transform: rotate(0deg);
      background-color:$color-teal;
    }
  }
}




.field-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  .field-row-element {
    width:45%;
    display: flex;
    align-items: center;
    .lbl {
      color:#fff;
      @include font-roboto-bold();
      margin-right: 15px;
    }
    input {
      padding:10px 20px;
      @include calc(width,'100% - 40px');
      color:#fff;
      @include font-roboto-regular();
      font-size: 18px;
      background-color: $color-teal;
      border: 1px solid $color-teal;
      @include set-input-placeholder-color(#fff);
      &.error {
        border: 1px solid $color-error;
      }
    }
    select {
      -webkit-appearance:none;
      cursor: pointer;
      padding:10px 30px 10px 20px;
      width:100%;
      color:#fff;
      @include font-roboto-regular();
      background-color: $color-teal;
      border: 1px solid $color-teal;
      font-size: 18px;
      border-radius: 0;
      background-image: url(../images/global/icon-angle-down.svg);
      background-repeat: no-repeat;
      background-position: 95% 50%;
      background-size: 14px 14px;
      &.error {
        border: 1px solid $color-error;
      }
    }
  }
}


.btn-outer {
  a {
    text-decoration: none!important;
  }
  .btn {
    cursor: pointer;
    border: none;
    padding:10px 20px;
    background-color: #358280;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include transition-all();

    .btn-txt {
      @include font-ntf-grand();
      font-size: 24px;
      color:#fff;
      word-break: keep-all;
      min-width: fit-content;
      text-transform: uppercase;
    }
    .btn-crt {
      @include transition-all();
      margin-left: 10px;
      svg {
        top:2px;
        width:8px;
      }
    }
    &:hover {
      background-color: $color-teal;
      .btn-crt {
        transform: translateX(2px);
      }
    }
  }//btn
}


.scrim-square {
  background-image: linear-gradient(#F15268 0%, #F6AE5E 100%);
}


.link-center {
  &:after {
    content: "";
    position: absolute;
    bottom:-5px;
    left: 50%;
    transform: translate(-50%, -50%);
    width:0;
    @include transition-all();
    height: 1px;
    background-color: #707070;
    max-width: 50px;
  }
  &.active,
  &:hover {
    &:after {
      width:50%;
    }
  }
}
.link-center-icon {
  .txt {
    &:after {
      content: "";
      position: absolute;
      bottom:-5px;
      left: 50%;
      transform: translate(-50%, -50%);
      width:0;
      @include transition-all();
      height: 2px;
      background-color: #000;
      //max-width: 50px;
    }
  }

  &.active,
  &:hover {
    .txt {
      &:after {
        width:100%;
      }
    }

  }
}

.current-menu-user {
  @include font-ntf-grand();
  font-size: 18px;
  color: $color-dark;
  //text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    width:16px;
    margin-right: 10px;
  }
  .sep {
    margin:0 5px;
  }
}


.sr-box {
  visibility: hidden;
}
input[type="checkbox"] {
  cursor: pointer;
  transform: scale(1.5);
  transform-origin: 100% 50%;
  body.browser_safari & {
    transform-origin: 0% 100%;
  }
  width:40px!important;
}